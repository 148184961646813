import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('src/pages/HomePage.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('pages/LoginPage.vue'),
      },
      {
        path: 'site',
        name: 'Site',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'users/:userId(\\d+)?',
        name: 'Users',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'formats',
        name: 'Formats',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'pricelists',
        name: 'PriceLists',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'descriptions',
        name: 'Descriptions',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'shipping',
        name: 'Shipping',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'boxes',
        name: 'Boxes',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'orders/:orderId(\\d+)?',
        name: 'Orders',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'images',
        name: 'Images',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'tags',
        name: 'Tags',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'promos',
        name: 'Promos',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'collections',
        name: 'Collections',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'stockists',
        name: 'Stockists',
        component: () => import('pages/SitePage.vue'),
      },
      {
        path: 'forms',
        name: 'Forms',
        component: () => import('pages/SitePage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
]

export default routes
